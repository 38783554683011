@font-face {
  font-family: "BigCaslonFB";
  src: url("./fonts/BigCaslonFB-Regular.ttf");
}

@font-face {
  font-family: "BigCaslonFB-Bold";
  src: url("./fonts/BigCaslonFB-Bold.ttf");
}

@font-face {
  font-family: "BigCaslon-SmallCaps";
  src: url("./fonts/BigCaslon-SmallCaps.ttf");
}

@font-face {
  font-family: "BigCaslon-Expert";
  src: url("./fonts/BigCaslon-Expert.ttf");
}

html {
  scroll-behavior: smooth;
}

:root {
  --primary-color: #ada298;
  --secondary-color: #43121d;
  --third-color: #f98169;
  --white-color: #ffffff;
  --text-color: #555;
  --text-gray: #999;
  --black-color: #000;
  --primary-font: "BigCaslonFB";
  --secondary-font: "BigCaslonFB-Bold";
  --thirty-font: "BigCaslon-SmallCaps";
  --forth-font: "BigCaslon-Expert";
}

body {
  font-family: var(--primary-font);
  font-size: 100%;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.nav-link {
  font-family: var(--primary-font);
  text-transform: capitalize;
  color: #ada298 !important;
  letter-spacing: 0.6px;
}

.navbar-brand,
h1 {
  font-family: var(--thirty-font);
  color: var(--primary-color) !important;
  font-size: 31px !important;
}
.navbar-brand:hover {
  color: #ada298 !important;
}

.nav-link:hover {
  transition: all 0.5s !important;
}
.navbar-nav {
  display: flex;
  align-items: center;
  gap: 15px;
}

.sidenav a {
  font-family: var(--primary-font);
}
.sidenav a:hover {
  color: #fff !important;
}
ul li {
  list-style: none;
}
.positionFixed {
  position: fixed;
  width: 100%;
  top: 0%;
  z-index: 99;
  transition: 0.5s;
}

.carousel-indicators [data-bs-target] {
  height: 9px !important;
  width: 9px !important;
  border-radius: 50%;
  margin-right: 14px !important;
}
h4,
h5,
h2 {
  color: #42413f !important;
}
h5 {
  font-family: var(--primary-font);
  text-align: justify;
}
p {
  font-family: var(--primary-font);
  text-align: justify;
  font-size: 19px;
  letter-spacing: 0.5px;
}

h2 {
  font-family: var(--secondary-font);
  text-transform: uppercase;
}

.horizontalLine {
  position: relative;
}

h3 {
  font-size: 1.1rem !important;
  font-family: var(--thirty-font);
  text-align: left;
  line-height: 2 !important;
  margin-bottom: 0;
}

.horizontalLine::before {
  content: "";
  display: block;
  width: 42%;
  height: 2px;
  background: #42413f;
  left: 0;
  top: 50%;
  position: absolute;
}

.horizontalLine::after {
  content: "";
  display: block;
  width: 42%;
  height: 2px;
  background: #42413f;
  right: 0;
  top: 50%;
  position: absolute;
}
h4 {
  font-family: var(--thirty-font);
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 20px !important;
  letter-spacing: 0.6px;
}
address {
  font-family: var(--primary-font);
  text-transform: uppercase;
  font-size: 17px;
  line-height: 24px !important;
}

a:hover {
  color: #3366cc !important;
}

i.fa-bars {
  color: var(--primary-color);
}

h6.update {
  font-family: var(--primary-font);
  text-align: center;
  font-size: 25px;
  color: #fff;
  margin: auto;
}

.updateDiv {
  background-color: #00000054;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.carousel-indicators {
  display: none !important;
}

.sidenav h3 {
  color: #a2a297 !important;
  font-family: var(--thirty-font);
  text-transform: uppercase;
  font-size: 19px !important;
  margin-bottom: 0;
}
.dropDownList {
  padding: 0 50px;
}

.sidenav ul {
  color: #a2a297 !important;
  font-family: var(--thirty-font);
  text-transform: uppercase;
}

.breadcrumb-item a[role="button"] {
  text-decoration: none;
  color: #42413f !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
}

.BigImage {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  height: 70% !important;
  transition: all 5s;
}

.card-body a:hover {
  color: #000 !important;
}

.carousel-control-next {
  right: 0px;
  opacity: 0.5;
}

.carousel-control-prev {
  opacity: 0.5;
  left: 10px;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 1 !important;
}
.modal-header {
  position: absolute;
  background-color: #00000054;
  right: 0;
  border-radius: 50%;
  padding-left: 10px;
  z-index: 2;
}

.carousel-control-next-icon {
  background-image: url("./Images/left-arrow-next-svgrepo-com.svg") !important;
  rotate: 180deg;
  height: 20px !important;
  /* background: none !important; */
}

.carousel-control-prev-icon {
  background-image: url("./Images/left-arrow-next-svgrepo-com.svg") !important;
  height: 20px !important;
}

.productOptionsSlide .carousel-control-prev1 {
  position: absolute;
  top: 0% !important;
  rotate: 90deg;
  left: 46%;
  background: none !important;
}

.productOptionsSlide .carousel-control-next1 {
  position: absolute;
  bottom: 0% !important;
  rotate: 270deg;
  left: 46%;
}

.productOptionsSlide .carousel-control-prev-icon {
  background-image: url("./Images/left-arrow-next-svgrepo-com.svg") !important;
}

.productOptionsSlide .carousel-control-next-icon {
  background-image: url("./Images/left-arrow-next-svgrepo-com.svg") !important;
  rotate: 180deg;
}
.productOptionsSlide .carousel-control-next {
  top: 100% !important;
  rotate: 90deg;
  left: 37%;
  background: none !important;
}

.rts___btn {
  font-family: var(--primary-font);
}

.rts___tab.rts___btn {
  text-transform: uppercase;
}

.rts___tab___selected a:hover {
  color: #5e0404 !important;
}

.rts___btn a:hover {
  text-decoration: none;
  color: #4d4b49 !important;
}

._DDceb, ._1lgEi{
  font-size: 29px !important;
}


._2IJEr:hover, ._2IJEr:focus, ._2Yv4j svg:hover, ._2Yv4j svg:focus, ._1lgEi span:hover, ._DDceb:hover, ._2Vept:hover {
  text-decoration: none;

  cursor: pointer;

  color: rgb(230, 230, 230);

}
*, ::after, ::before {
  box-sizing: border-box;

}
*, ::after, ::before {
  box-sizing: border-box;

}
._1qbJe:hover {
  color: rgb(255, 255, 255) !important; 

}

._1qbJe{
  color: #717070 !important; 
}
.rts___tab.rts___btn button {
  background: none;
  border: navajowhite;
  text-transform: uppercase;
}