/* mobile */

@media (min-width: 320px) and (max-width: 767px) {
  ._2Yv4j {
    position: fixed;
    left: 203px !important;
  }
  ._2Yv4j svg{
    padding: 0px !important;
  }
  section._2Yv4j._11aT5.imageModal{
    gap: 20px !important;
  }
  ._ABXHD{
    gap: 20px !important
  }
  #testimonial h2::before {
    width: 20% !important;
    top: 35%;
  }
  #testimonial h2::after {
    width: 20% !important;
    top: 35%;
  }
  #testimonial h4 {
    font-size: 1rem !important;
  }
  #testimonial .testimonalSubHeading {
    font-size: 14px !important;
  }
  .navbar-brand {
    width: 90%;
    align-items: center;
    display: flex;
    margin-right: 0px !important;
  }
  /* .navbar-brand img{
    height: 18px !important;
  } */

  .navbar-toggler {
    border: none !important;
    font-size: 14px !important;
  }
  .productOptionsSlide .carousel-control-prev {
    left: 0;
    rotate: 0deg;
    top: 32% !important;
    background: none !important;

    /* opacity: 0.8; */
  }

  li.breadcrumb-item {
    font-size: 13px;
  }
  .productOptionsSlide .carousel-control-next-icon {
    rotate: 0deg;
  }

  .productOptionsSlide .carousel-control-next {
    left: 87%;
    rotate: 180deg;
    background: none !important;
    top: 32% !important;
    /* opacity: 0.8; */
  }

  h2::before,
  h2::after {
    width: 28% !important;
  }
  .center .text,
  .akshay .text,
  .item:hover .text {
    font-size: 13px !important;
  }
  .owl-carousel .owl-item img {
    height: auto !important;
  }
  .fa-bars:before,
  .fa-navicon:before {
    font-size: 20px;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 1.5rem !important;
    height: 20px !important;
  }
  .optionImages {
    height: 80px !important;
    width: 100%;
  }
  .sliderOptionsImg {
    margin: auto !important;
    display: flex !important;
    gap: 20;
  }
  h4 {
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    padding-bottom: 6px;
  }
  /* #navbarSupportedContent{
        display: none;
    } */
  h2 {
    font-size: 23px !important;
  }
  .productOptionsSlide .carousel-control-prev1 {
    left: 0;
    rotate: 0deg;
    top: 1;
  }
  p {
    font-size: 17px;
  }
  .navbar-toggler1 {
    display: block !important;
    width: 30px !important;
  }
  .nav-link,
  .nav-link i {
    font-size: 0.9rem !important;
  }
  #product p {
    font-size: 17px !important;
  }
  .menuBar {
    width: 74% !important;
  }
  h6.update {
    font-size: 17px;
  }
  .sidenav a {
    font-size: 14px !important;
  }
  .sidenav h3 {
    font-size: 15px !important;
  }
  .sidenav .closebtn {
    font-size: 40px !important;
  }
  .dropDownList {
    padding: 0 30px;
  }
  /* .listImageStyle {
    width: 40% !important;
    padding-right: 0px !important;
  } */
  .productImgStyle {
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  }
  .modal-dialog {
    margin: 20px !important;
  }
  .modal-dialog {
    max-width: 550px !important;
  }
  .mobileImage {
    height: auto;
    width: 100%;
  }
  .modal-footer {
    padding-bottom: 0px !important;
  }
  /* .smallScrollImage{
    flex-direction: row;
    overflow: scroll;
    padding-top: 20px;
    display: flex;
    gap:20px;
    height: auto !important;
  } */
  .productOptionsSlide {
    height: auto;
    overflow: scroll;
  }
  .closeBtn {
    right: -3% !important;
  }
  .displatedSlideoptioVoew {
    width: 150px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  h2::before,
  h2::after {
    width: 40%;
  }

  #testimonial h2::after {
    width: 35% !important;
    top: 35% !important;
  }
  #testimonial h2::before {
    width: 35% !important;
    top: 35% !important;
  }
  .navbar-toggler1 {
    display: block !important;
    width: auto !important;
  }
  .nav-link {
    padding: 0.5rem 1rem !important;
  }
  .nav-link,
  .nav-link i {
    font-size: 22px !important;
  }
  .navbar-brand,
  h1 {
    font-size: 29px !important;
  }
  .navbar-brand img {
    width: 460px !important;
  }
  #product p,
  p {
    font-size: 17px;
  }
  h4 {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  h2 {
    font-size: 24px !important;
  }
  .item:hover .text {
    font-size: 15px !important;
  }
  h3 {
    line-height: 1.7 !important;
  }
  .sidenav h3 {
    font-size: 18px !important;
  }
  .sidenav a {
    font-size: 17px !important;
  }
  .sidenav .closebtn {
    font-size: 50px !important;
  }
  .dropDownList {
    padding: 18px 50px 0px 50px;
  }
  .sidenav ul {
    margin-top: 13px;
  }
  iframe {
    height: 100%;
  }
  .modal-dialog {
    max-width: 550px !important;
  }
  .mobileImage {
    height: auto;
    width: 100%;
  }
  .productImgStyle {
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
  .productOptionsSlide {
    height: 350px;
    overflow: hidden;
  }
  .productOptionsSlide .carousel-control-next-icon {
    rotate: 0deg;
  }
  .navbar-brand {
    width: 85%;
    align-items: center;
    display: flex;
  }
}
@media (min-width: 991px) and (max-width: 1023px) {
  #navbarSupportedContent {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .productImgStyle {
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
}

/* ipad-pro */
@media (min-width: 1024px) and (max-width: 1366px) {
  .navbar-nav {
    gap: 10px;
  }

  #testimonial h2::before {
    width: 36% !important;
    top: 36% !important;
  }

  #testimonial h2::after {
    width: 36% !important;
    top: 36% !important;
  }

  .productImgStyle {
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }
  .navbar-brand {
    width: 45%;
    align-items: center;
    display: flex;
  }
  /* .navbar-brand img{
    height: 25px !important
  } */

  h2::before,
  h2::after {
    width: 40%;
  }
  .nav-link {
    font-size: 18px !important;
  }
  h4 {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 30px !important;
  }
  #product p,
  p {
    font-size: 17px;
  }
  .item:hover .text {
    font-size: 18px !important;
    line-height: 30px !important;
  }
  iframe {
    height: 100%;
  }
  .modal-dialog {
    max-width: min-content !important;
  }
  .mobileImage {
    /* height: 550px !important; */
    width: auto !important;
  }

  /* .mobileImage {
    height: auto;
    width: 100%;
 } */
  .productSliderStyle {
    width: 100% !important;
  }
  .productOptionsSlide {
    height: 500px;
    /* overflow-y: scroll; */
  }

  .optionImages {
    height: 80x;
    width: 100%;
  }
}

@media (min-width: 1367px) and (max-width: 1920px) {
  .modal-dialog {
    max-width: min-content !important;
  }
  .productImgStyle {
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }
  .productSliderStyle {
    width: 75% !important;
  }
  .productOptionsSlide {
    height: 550px;
    /* overflow-y: scroll; */
  }
  .navbar-brand {
    width: 47%;
    align-items: center;
    display: flex;
  }
  .nav-link {
    font-size: 19px !important;
  }
}

@media (min-width: 1921px) and (max-width: 2560px) {
  .modal-dialog {
    max-width: min-content !important;
    /* background-color: red; */
  }
  .mobileImage {
    height: 970px !important;
  }
  /* .modal-content button img{
    width: 35px !important;
  } */
  .productImgStyle {
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }
  .productOptionsSlide {
    height: 550px;
    /* overflow-y: scroll; */
  }
  a.navbar-brand {
    width: 50%;
  }
}
/* laptop */

@media (min-width: 1920px) {
  h3 {
    font-size: 17px !important;
    font-weight: bold;
  }
  .item:hover .text {
    font-size: 34px !important;
    line-height: 47px !important;
  }
  h4 {
    font-weight: 600 !important;
  }
  .productImgStyle {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 2561px) and (max-width: 2580px) {
  a.navbar-brand img {
    width: 800px !important;
  }
  .mobileImage {
    height: auto !important;
    width: 100%;
  }
}

p {
  color: #42413f;
}

.footerLogo {
  font-size: 23px !important;
  line-height: 34px !important;
}
.footerSubLogo {
  font-size: 16px;
  margin-bottom: 0.2rem;
  color: #ada298;
}

.listImageStyle {
  display: grid;
  margin: auto;
  width: auto;
  padding-right: 10px;
  padding-left: 10px;
}

.modal-body {
  padding: 0 !important;
}

.modal-content {
  margin: auto;
  /* max-width: min-content; */
  transition: all 0.5s;
  top: 30px;
}
.disabled {
  opacity: 1 !important;
  cursor: pointer;
}

.notdiable {
  opacity: 0.5 !important;
  cursor: pointer;
}

.akshay {
  height: 500px !important;
}

.imageViewer .carousel-control-prev {
  background: transparent !important;
}

.imageViewer .carousel-control-next {
  background: transparent !important;
}

/* #0000008c */
#slideshowAnim {
  background-color: rgba(21, 21, 21, 0.89) !important;
}

._ABXHD div:nth-child(3) {
  display: none;
}

._ABXHD div:nth-child(4) {
  display: none;
}
